import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@material-ui/core';
import { scroller } from 'react-scroll';
import { ArrowForward, ExpandMore } from '@material-ui/icons';
import cover from "../../assets/images/cover.jpg";

const Fade = require('react-reveal/Fade')

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'rgb(232, 232, 232)',
        '& h4': {
            fontFamily: 'Raleway',
            textTransform: 'uppercase',
            marginTop: '60px',
        },
        '& h5': {
            fontFamily: 'Raleway',
            textTransform: 'uppercase',
            padding: '10px',
            textAlign: 'center',
        },
    },

    divider: {
        width: '40px',
        border: 0,
    },

    card: {
        transition: 'all .2s ease-in-out',
        width: '300px',
        height: '480px',
        '&:hover': {
            transform: 'scale(1.08)',
        },
        '& img': {
            marginTop: '-40px',
            backgroundColor: '#653A96',
            borderRadius: 50,
            width: '80px',
            height: '80px',
        },
    },

    expandButton: {
        marginTop: '20px',
        color: '#4a4a4a',
        zIndex: 2,
    },
});

const cleaning = [
    '1 chimney: €60',
    'extra chimneys: + €40',
    'Pellet stove: €150',
];
const cleaningSecondary = [
    ,
    'Per household',
    ,
]

const openFires = [
    'Open Fire',
    'Solid Fuel Stove',
    'Pellet Stove',
    'Stove Rope: €20',
];

const survey = [
    'Camera Survey: €160',
    

];

export const Services: React.FC = () => {
    const classes = useStyles();

    const handleExpandClick = () => {
        scroller.scrollTo("gallery", {
            duration: 1500,
            delay: 100,
            smooth: true,
        });
    };
    return (
        <div id='services' className={classes.root}>
            <Typography variant='h4' style={{ color: '#4a4a4a' }}>
                Services
      </Typography>
            <hr
                className={classes.divider}
                style={{ borderTop: '2px solid #653A96' }}
            />

            <Typography
                style={{
                    color: '#4a4a4a',
                    padding: '20px',
                    width: '50%',
                    textAlign: 'center',
                }}>
                How much does it cost ?
      </Typography>

            <hr
                className={classes.divider}
                style={{ borderTop: '2px solid #4a4a4a' }}
            />

            <Grid
                container
                direction={'row'}
                justify='center'
                alignContent='center'
                spacing={8}
                style={{ marginTop: '30px', marginBottom: '10px', width: '100%' }}>
                <Grid item>
                    <Paper elevation={3} className={classes.card}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img
                                alt='stoves'
                                src={cover}
                            />
                        </div>
                        <Typography variant='h5'>Cleaning services</Typography>
                        <hr
                            className={classes.divider}
                            style={{ borderTop: '2px solid #4a4a4a' }}
                        />

                        <List>
                            {cleaning.map((item, index) => (
                                <Fade top cascade duration={+index * 300}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ArrowForward
                                                fontSize='small'
                                                style={{ color: '#653A96' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item} secondary={cleaningSecondary[index]} />
                                    </ListItem>
                                </Fade>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                <Grid item>
                    <Paper elevation={3} className={classes.card}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img
                                alt='Open Fires'
                                src={cover}
                            />
                        </div>
                        <Typography variant='h5'>Chimney Services</Typography>
                        <hr
                            className={classes.divider}
                            style={{ borderTop: '2px solid #4a4a4a' }}
                        />

                        <List>
                            {openFires.map((item, index) => (
                                <Fade top cascade duration={+index * 300}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ArrowForward
                                                fontSize='small'
                                                style={{ color: '#653A96' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                </Fade>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                <Grid item>
                    <Paper elevation={3} className={classes.card}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img
                                alt='Survey'
                                src={cover}
                            />
                        </div>
                        <Typography variant='h5'>Camera Survey</Typography>
                        <hr
                            className={classes.divider}
                            style={{ borderTop: '2px solid #4a4a4a' }}
                        />

                        <List>
                            {survey.map((item, index) => (
                                <Fade top cascade duration={+index * 300}>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ArrowForward
                                                fontSize='small'
                                                style={{ color: '#653A96' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                </Fade>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <IconButton
                className={classes.expandButton}
                onClick={handleExpandClick}
            >
                <ExpandMore fontSize="large" />
            </IconButton>
        </div>
    );
};